/* Modern CSS Reset and Base Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

body {
    background-color: #f9fafb;
    color: #1f2937;
    line-height: 1.5;
}

/* Container and Card Styles */
.container {
    max-width: 42rem;
    margin: 2rem auto;
    padding: 0 1rem;
}

.card {
    background: white;
    border-radius: 0.75rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    overflow: hidden;
}

.card-header {
    padding: 1.5rem;
    border-bottom: 1px solid #e5e7eb;
}

.card-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.card-description {
    color: #6b7280;
    font-size: 0.875rem;
}

.card-content {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* Button Styles */
.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-weight: 500;
    font-size: 0.875rem;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.2s;
    background-color: #18181b;
    color: white;
    width: 100%;
    height: 2.5rem;
}

.button.danger {
    background-color: #dc7676;
    color: white;
}

.button.danger:hover:not(:disabled) {
    background-color: #d76565;
}

.button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}


.button:hover:not(:disabled) {
    background-color: #27272a;
}

/* Select Styles */
.select-wrapper {
    position: relative;
    width: 100%;
}

select {
    width: 100%;
    padding: 0.5rem 2.5rem 0.5rem 0.75rem;
    background-color: transparent;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    appearance: none;
    font-size: 0.875rem;
    line-height: 1.25rem;
    height: 2.5rem;
    color: #1f2937;
}

select:disabled {
    background-color: #f3f4f6;
    cursor: not-allowed;
}

.select-wrapper::after {
    content: '';
    position: absolute;
    right: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    width: 0.75rem;
    height: 0.75rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
    pointer-events: none;
}

/* Console Styles */
.console-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.console {
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    height: 500px;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.log-error { color: #ef4444; }
.log-success { color: #22c55e; }

.footer {
    padding: 0.5rem 0;
    border-top: 1px solid #e5e7eb;
    color: #6b7280;
    font-size: 0.875rem;
}

/* Terminal Icon */
.terminal-icon {
    width: 1rem;
    height: 1rem;
}

.terminal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.5rem;
    background-color: #ffffff;
    border-radius: 0.375rem;
}

.xterm {
    height: 100%;
    width: 100%;
    padding: 8px;
}

.xterm-viewport {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    background-color: #ffffff !important;
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
}

.xterm-scroll-area {
    overflow: auto !important;
    position: absolute !important;
    right: 0;
    left: 0;
}

.xterm-screen {
    position: absolute !important;
    width: 100% !important;
    height: auto !important;
}

.xterm-text-layer,
.xterm-selection-layer,
.xterm-link-layer,
.xterm-cursor-layer {
    width: 100% !important;
    height: 100% !important;
}

/* Re-enable these styles but modify them */
.xterm-helper-textarea {
    position: absolute !important;
    opacity: 0 !important;
    left: -9999em !important;
    top: 0 !important;
    width: 0 !important;
    height: 0 !important;
    z-index: -10 !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    resize: none !important;
}

.xterm-char-measure-element {
    display: inline-block;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: -9999em;
    line-height: normal;
}

/* Add this new section for the form layout */
div[id="targetChip"], div[id="firmwareVersion"] {
    display: inline-block;
    width: calc(50% - 0.5rem);
}

div[id="targetChip"] {
    margin-right: 1rem;
}

/* Add a new wrapper class for these two specific areas */
.select-row {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.select-row > div:first-child {
    flex: 4;  /* Takes up 4 parts */
}

.select-row > div:last-child {
    flex: 8;  /* Takes up 8 parts */
}

.button-row {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.button-row > button:first-child {
    flex: 9;  /* Takes up 4 parts */
}

.button-row > button:last-child {
    flex: 3;  /* Takes up 8 parts */
}

.header-content {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.header-logo {
    flex-shrink: 0;
}

.header-text {
    flex-grow: 1;
}

.header-text .card-title {
    margin-bottom: 0.25rem;
}